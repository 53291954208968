exports.components = {
  "component---src-blog-templates-blog-page-tsx": () => import("./../../../src/blog/templates/BlogPage.tsx" /* webpackChunkName: "component---src-blog-templates-blog-page-tsx" */),
  "component---src-blog-templates-blog-post-tsx": () => import("./../../../src/blog/templates/BlogPost.tsx" /* webpackChunkName: "component---src-blog-templates-blog-post-tsx" */),
  "component---src-blog-templates-blog-tag-tsx": () => import("./../../../src/blog/templates/BlogTag.tsx" /* webpackChunkName: "component---src-blog-templates-blog-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */)
}

